<div class="max-w-sm rounded overflow-hidden shadow-lg bg-white m-4">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">User Management</div>
      <p class="text-gray-700 text-base">
      </p>
    </div>
    <div class="px-6 py-4">
      <div class="mb-4">
        <p class="text-gray-900 font-semibold">User Overview:</p>
        <p class="text-gray-700">New Users: </p>
        <p class="text-gray-700">Super Active Users: </p>
        <p class="text-gray-700">Inactive Users: </p>
        <p class="text-gray-700">Total Users: </p>
      </div>
      <div class="mb-4">
        <p class="text-gray-900 font-semibold">User Actions:</p>
      </div>
      <div class="flex flex-wrap -mx-2">
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" (click)="viewUsers()">
            View Users
          </button>
        </div>
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full" (click)="addUser()">
            Add User
          </button>
        </div>
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded w-full" (click)="manageKYC()">
            Manage KYC
          </button>
        </div>
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full" (click)="deactivateUsers()">
            Deactivate Users
          </button>
        </div>
      </div>
    </div>
  </div>
  