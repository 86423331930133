import { Component } from '@angular/core';

@Component({
  selector: 'app-reporting-analytics-card',
  standalone: true,
  imports: [],
  templateUrl: './reporting-analytics-card.component.html',
  styleUrl: './reporting-analytics-card.component.css'
})
export class ReportingAnalyticsCardComponent {

}
