<div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">User Management</div>
      <p class="text-gray-700 text-base">
        Manage user registrations, profiles, and KYC status.
      </p>
    </div>
    <div class="px-6 pt-4 pb-2">
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2">
        View Users
      </button>
      <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        Add User
      </button>
    </div>
  </div>
  <p>compliance-security-card works!</p>
