import { Component } from '@angular/core';

@Component({
  selector: 'app-integration-management-card',
  standalone: true,
  imports: [],
  templateUrl: './integration-management-card.component.html',
  styleUrl: './integration-management-card.component.css'
})
export class IntegrationManagementCardComponent {

}
