<div class="container mx-auto p-4">
        <Span> Work in progress, displaying pllace holders</Span>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <app-user-management-card></app-user-management-card>
    <app-transaction-management-card></app-transaction-management-card>
    <app-account-management-card></app-account-management-card>
    <app-compliance-security-card></app-compliance-security-card>
    <app-reporting-analytics-card></app-reporting-analytics-card>
    <app-support-helpdesk-card></app-support-helpdesk-card>
    <app-system-administration-card></app-system-administration-card>
    <app-notifications-alerts-card></app-notifications-alerts-card>
    <app-integration-management-card></app-integration-management-card>
    <app-backup-recovery-card></app-backup-recovery-card>
    </div>
  </div>