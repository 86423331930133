<div class="max-w-sm rounded overflow-hidden shadow-lg bg-white m-4">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">Support Desk</div>
      <p class="text-gray-700 text-base">
        Manage and resolve support tickets efficiently.
      </p>
    </div>
    <div class="px-6 py-4">
      <div class="mb-4">
        <p class="text-gray-900 font-semibold">Ticket Overview:</p>
        <p class="text-gray-700">Open Tickets: {{ openTickets }}</p>
        <p class="text-gray-700">Resolved Tickets: {{ resolvedTickets }}</p>
        <p class="text-gray-700">Pending Tickets: {{ pendingTickets }}</p>
        <p class="text-gray-700">High-Priority Tickets: {{ highPriorityTickets }}</p>
      </div>
      <div class="mb-4">
        <p class="text-gray-900 font-semibold">Support Actions:</p>
      </div>
      <div class="flex flex-wrap -mx-2">
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" (click)="viewTickets()">
            View Tickets
          </button>
        </div>
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full" (click)="resolveTickets()">
            Resolve Tickets
          </button>
        </div>
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded w-full" (click)="manageFAQs()">
            Manage FAQs
          </button>
        </div>
        <div class="w-full sm:w-1/2 px-2 mb-4">
          <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full" (click)="exportTicketData()">
            Export Data
          </button>
        </div>
      </div>
    </div>
  </div>
  