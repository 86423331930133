<div class="max-w-sm rounded overflow-hidden shadow-lg bg-white m-4">
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">Account Management</div>
    <p class="text-gray-700 text-base">
    </p>
  </div>
  <div class="px-6 py-4">
    <div class="mb-4">
      <p class="text-gray-900 font-semibold">Account Balance:</p>
      <p class="text-gray-700">Available: </p>
      <p class="text-gray-700">Pending: </p>
    </div>
    <div class="mb-4">
      <p class="text-gray-900 font-semibold">Account Status:</p>
      <p class="text-gray-700">Active</p>
    </div>
    <div class="flex flex-wrap -mx-2">
      <div class="w-full sm:w-1/2 px-2 mb-4">
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" (click)="viewBalance()">
          View Balance
        </button>
      </div>
      <div class="w-full sm:w-1/2 px-2 mb-4">
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full" (click)="viewStatement()">
          View Statement
        </button>
      </div>
      <div class="w-full sm:w-1/2 px-2 mb-4">
        <button class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded w-full" (click)="lockAccount()">
          Lock Account
        </button>
      </div>
      <div class="w-full sm:w-1/2 px-2 mb-4">
        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full" (click)="closeAccount()">
          Close Account
        </button>
      </div>
    </div>
  </div>
</div>
