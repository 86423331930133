import { Component } from '@angular/core';

@Component({
  selector: 'app-compliance-security-card',
  standalone: true,
  imports: [],
  templateUrl: './compliance-security-card.component.html',
  styleUrl: './compliance-security-card.component.css'
})
export class ComplianceSecurityCardComponent {

}
