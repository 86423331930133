import { Component } from '@angular/core';
import { AccountManagementCardComponent } from '../desktop/account-management-card/account-management-card.component';
import { ComplianceSecurityCardComponent } from '../desktop/compliance-security-card/compliance-security-card.component';
import { IntegrationManagementCardComponent } from '../desktop/integration-management-card/integration-management-card.component';
import { NotificationsAlertsCardComponent } from '../desktop/notifications-alerts-card/notifications-alerts-card.component';
import { ReportingAnalyticsCardComponent } from '../desktop/reporting-analytics-card/reporting-analytics-card.component';
import { SupportHelpdeskCardComponent } from '../desktop/support-helpdesk-card/support-helpdesk-card.component';
import { SystemAdministrationCardComponent } from '../desktop/system-administration-card/system-administration-card.component';
import { TransactionManagementCardComponent } from '../desktop/transaction-management-card/transaction-management-card.component';
import { UserManagementCardComponent } from '../desktop/user-management-card/user-management-card.component';
import { BackupRecoveryCardComponent } from '../desktop/backup-recovery-card/backup-recovery-card.component';

@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [AccountManagementCardComponent,
    ComplianceSecurityCardComponent,
    IntegrationManagementCardComponent,
    NotificationsAlertsCardComponent,
    ReportingAnalyticsCardComponent,
    SupportHelpdeskCardComponent,
    SystemAdministrationCardComponent,
    TransactionManagementCardComponent,
    UserManagementCardComponent,
    BackupRecoveryCardComponent

  ],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.css'
})

export class CustomerComponent {

}
